import * as React from "react"
import { FC } from "react"
import { GlobalLayout } from "@/components/GlobalLayout"
import { Head } from "@/components/Head"
import { navigate } from "gatsby"

const { useEffect } = React

interface Props {
    location: any
}

export const Complete: React.FC<Props> = ({ location }) => {
    useEffect(() => {
        if (!location?.state?.toAddress) {
            navigate("/")
        }
    }, [])

    return (
        <CompleteTemplate
            toAddress={location?.state?.toAddress}
            message={
                <>
                    チケット予約いただき誠にありがとうございます。
                    <br />
                    メールを下記アドレスに送信いたしますので、ご確認をお願いいたします。
                </>
            }
            annotationTexts={[
                "※10分以内に自動送信されます。",
                "※メールが届かない場合、設定により迷惑メールに振り分けれられる場合があるため、一度迷惑メールフォルダをご確認ください。",
                "※メールアドレスを誤ってしまった場合、お手数ですが正しいメールアドレスで再度チケットの予約をお願いいたします。（その他要望項目に誤って設定した旨記載いただけると幸いです）",
            ]}
        />
    )
}

interface LiveBookingCompleteTemplate {
    toAddress: string | undefined
    message: React.ReactNode
    annotationTexts: string[]
}

export const CompleteTemplate: React.FC<LiveBookingCompleteTemplate> = ({
    toAddress,
    message,
    annotationTexts,
}) => {
    return (
        <GlobalLayout Head={<Head title="send" noIndex />}>
            <section className="p-section u-mb-100px">
                {toAddress && (
                    <>
                        <div
                            className={
                                "w-3/4 rsm:w-11/12 mr-auto ml-auto mt-14 text-base leading-none"
                            }
                        >
                            <CompleteTextTemplate
                                toAddress={toAddress}
                                message={message}
                                annotationTexts={annotationTexts}
                            />
                        </div>
                    </>
                )}
            </section>
        </GlobalLayout>
    )
}

const CompleteTextTemplate: FC<LiveBookingCompleteTemplate> = ({
    toAddress,
    message,
    annotationTexts,
}) => (
    <>
        <p className={"u-mb-5"}>{message}</p>
        <AnnotationList list={annotationTexts} />
        <code className={"bg-gray-100 pt-4 pb-4 pl-2 block rounded-sm"}>
            {toAddress}
        </code>
    </>
)

const AnnotationList = (props: { list: string[] }) => {
    return (
        <ul className={"mb-5"}>
            {props.list.map((l) => (
                <li className={"text-xs text-gray-500 mb-3"}>{l}</li>
            ))}
        </ul>
    )
}
