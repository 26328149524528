import * as React from "react"
import { useEffect } from "react"
import { CompleteTemplate } from "@/components/pages/LiveBooking/Complete"
import { navigate } from "gatsby"

interface Props {
    location: any
}

export const Complete: React.FC<Props> = ({ location }) => {
    useEffect(() => {
        if (!location?.state?.toAddress) {
            navigate("/")
        }
    }, [])

    return (
        <CompleteTemplate
            toAddress={location?.state?.toAddress}
            message={
                <>
                    チケット内容の変更リクエストを受け付けました。
                    <br />
                    メールを下記アドレスに送信いたしますので、ご確認をお願いいたします。
                </>
            }
            annotationTexts={["※10分以内に自動送信されます。"]}
        />
    )
}
